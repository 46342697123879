<!--权限控制-权限资源-->
<template>
<section>
  <!-- 工具条 -->
  <el-form :inline='true' class='toolbar' style='padding-bottom: 0px;'>
    <el-form-item>
      <el-input size='mini' v-model.trim='filterText' placeholder='关键字搜索'>
        <el-button slot='prepend' icon='el-icon-refresh' @click='getData'></el-button>
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button size='mini' type='primary' @click='handleAdd'>新增</el-button>
    </el-form-item>
  </el-form>
  <!-- 列表 -->
  <el-tree
    class='tree-list'
    :indent='20'
    :data="treelist"
    :props="props"
    node-key="Id"
    :filter-node-method="handleSearch"
    ref='tree'
    default-expand-all
    :expand-on-click-node="false"
    :render-content="handlerRender">
  </el-tree>

    <!--编辑界面-->
    <el-dialog :title='dlg.title' :visible.sync='dlg.visible' @close='handlerClose'>
      <el-form :model='formData' label-width='80px' :rules='formRule' ref='formData' size='mini'>
        <el-col :span='12'>
        <el-form-item prop='Name' label='名称' >
          <el-input @change="hasChange" size='small' v-model.trim='formData.Name' ></el-input>
        </el-form-item>
        <el-form-item prop='Sign' label='权限标识' >
          <el-input @change="hasChange" size='small' v-model.trim='formData.Sign' ></el-input>
        </el-form-item>
        <el-form-item prop='Typ' label='资源类型' >
          <el-select @change="hasChange"  size='mini' v-model.trim='formData.Typ' clearable placeholder='请选择'>
            <el-option
              v-for="item in restype"
              :key="item.K"
              :label="item.V"
              :value="item.K">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop='Color' label='颜色' >
          <el-input-number @change="hasChange"  size='mini' v-model.trim='formData.Color' :min="0"></el-input-number>
        </el-form-item>
        <el-form-item prop='Remark' label='备注' >
          <el-input @change="hasChange" size='small' v-model.trim='formData.Remark' ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span='12'>
        <el-form-item prop='Icon' label='图标' >
          <el-input @change="hasChange" size='small' v-model.trim='formData.Icon' ></el-input>
        </el-form-item>
        <el-form-item prop='Path' label='路径' >
          <el-input @change="hasChange" size='small' v-model.trim='formData.Path' ></el-input>
        </el-form-item>
        <el-form-item prop='Pid' label='父级' >
          <el-cascader
            :options='treelist'
            v-model.trim='formData.Pid'
            :props='props'
            clearable
            @change='hasChange'>
          </el-cascader>
        </el-form-item>
        <el-form-item prop='Odr' label='排序' >
          <el-input-number @change="hasChange" size='mini' v-model.trim='formData.Odr' :min="1" :max="100"></el-input-number>
        </el-form-item>
        <el-form-item prop='Status' label='状态' >
          <el-radio @change="hasChange" v-model.trim="formData.Status" size="mini" :label="1" >启用</el-radio>
          <el-radio @change="hasChange" v-model.trim="formData.Status" size="mini" :label="0" >禁用</el-radio>
        </el-form-item>
      </el-col>
      </el-form>
      <div slot='footer'>
        <el-button size='small' @click.native='dlg.visible=false'>取消</el-button>
        <el-button type='primary' size='small' @click.native='handleSubmit' >提交</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { treeFilter, join, toIntArray } from '@/util/index'
export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      treelist: null,
      sels: [], // 列表选中列
      restype: [],
      filterText: '',
      dlg: {
        visible: false,
        typ: null,
        title: ''
      },
      formData: {},
      formRule: {
        Name: [{ required: true, message: '名称不可为空', trigger: 'blur' }]
      }
    }
  },
  computed: mapState({
    props: state => Object.assign({}, state.props, { checkStrictly: true })
  }),
  created() {
    this.getData()
    this.$post('admin/listReferParam', { code: 'RES_TYPE' }).then(res => {
      this.restype = res
    }).catch(() => {})
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    hasChange() {
      this.changeState = true
    },
    getData: function() {
      this.$post('admin/listCaResource').then((res) => {
        this.treelist = treeFilter(res)
      })
    },
    selsChange: function(sels) {
      this.sels = sels
    },
    handlerRender(h, { node, data, store }) {
      return (
        <span>
          <span>{node.label}</span>
          <span>
            <i on-click={ () => this.handleEdit(data) } class='opt el-icon-edit' />
            <i on-click={ () => this.handleDel(data) } class='opt el-icon-delete' />
          </span>
        </span>)
    },
    // 搜索
    handleSearch(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handleAdd: function() {
      this.dlg.title = '新增'
      this.dlg.visible = true
      this.formData = { 'Pid': [] }
    },
    handleEdit: function(row) {
      this.dlg.title = '编辑'
      this.dlg.visible = true
      this.formData = Object.assign({}, row)
      this.formData.Pid = row.Pid === '' ? [] : toIntArray(row.Pid)
    },
    // 批量删除
    bulkRemove: function() {
      const ids = this.sels.map(item => item.Id).toString()
      this.$confirm('确认删除选中记录吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$post('admin/bulkRemoveCaResource', { ids: ids }).then((res) => {
          this.getData()
        })
      }).catch(() => {})
    },
    handleDel: function(data) {
      this.$confirm('确认删除该记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$get('admin/delCaResource', { id: data.Id }).then(() => { this.getData() })
      })
    },
    handleSubmit: function() {
      if (!this.changeState) {
        this.dlg.visible = false
        return
      }
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {}).then(() => {
            this.formData.Pid = join(this.formData.Pid)
            const para = Object.assign({}, this.formData)
            this.$post('admin/saveCaResource', para).then(() => {
              this.dlg.visible = false
            })
          }).catch(() => {})
        }
      })
    },
    handlerClose: function() {
      if (this.changeState) {
        this.changeState = false
        this.getData()
      }
      if (this.$refs['formData']) {
        this.$refs['formData'].resetFields()
      }
    }
  }
}
</script>
